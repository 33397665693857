export const colors = {
  default: '#FFFFFF',
  backgroundSecondary: '#F4F4F4',
  commonBlack: '#0D0D0D',
  primaryMain: '#202022',
  secondaryMain: '#EA3F33',
  successMain: '#7FC34A',
  textError: '#fa0500',
  textPrimary: '#202022',
  textSecondary: '#EA3F33',
}
