/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useInView } from 'react-intersection-observer'
import {
  Box,
  Container,
  Grid,
  Grow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import theme from '../../../../themes/theme'
import producto from '../../../../assets/img/p1.png'
import producto2 from '../../../../assets/img/p2.png'
import producto3 from '../../../../assets/img/producto_3.jpg'
import producto4 from '../../../../assets/img/p4.png'
import producto5 from '../../../../assets/img/p5.jpg'
import producto6 from '../../../../assets/img/p7.png'
import producto7 from '../../../../assets/img/producto_7.svg'
import producto8 from '../../../../assets/img/p3.png'

const ProductsLine = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const [ref, inView] = useInView({
    threshold: 0.2,
  })
  const images = [
    { img: producto, text: 'Maquinaria y Equipos', timeout: 800 },
    { img: producto2, text: 'Materiales', timeout: 1000 },
    { img: producto8, text: 'Productos de acero', timeout: 1200 },
    { img: producto3, text: 'Conductores', timeout: 1600 },
    { img: producto4, text: 'Generadores y Turbinas', timeout: 1800 },
    { img: producto5, text: 'Tuberías', timeout: 2000 },
    { img: producto6, text: 'Dispositivos eléctricos', timeout: 2200 },
    { img: producto7, text: 'Válvulas' },
  ]

  return (
    <Container maxWidth='xl' id='productos'>
      <Box sx={{ px: !matches ? 3 : '' }}>
        <Box mt={3}>
          <Box display='flex' flexWrap='wrap'>
            <Typography
              variant='h2'
              fontWeight={500}
              noWrap
              marginRight='0.75rem'
            >
              LINEA DE
            </Typography>
            <Typography variant='h2' color='secondary' fontWeight={800}>
              PRODUCTOS
            </Typography>
          </Box>
          <Box
            mt={2}
            sx={{
              height: '2px',
              backgroundColor: theme.palette.secondary.main,
              width: !matches ? '10.31rem' : '5rem',
            }}
          />
        </Box>
        <Grid container display='flex' mt={2} ref={ref}>
          {images.map((item, index) => (
            <Grid
              item
              xs={6}
              p={!matches ? 2 : 1}
              key={index}
            >
              <Grow in={inView} timeout={item.timeout}>
                <Box
                  sx={{
                    backgroundImage: `url(${item.img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: !matches ? '19.68rem' : '12.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                  }}
                >
                  <Typography
                    color={theme.palette.primary.light}
                    variant='h4'
                    fontWeight={600}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              </Grow>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default ProductsLine
