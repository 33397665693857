/* eslint-disable max-len */
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Container,
  Grid,
  Input,
  Typography,
  useMediaQuery,
} from '@mui/material'
import wasop from '../../../../assets/img/square-whatsapp.svg'
import emailIcon from '../../../../assets/img/envelope-solid.svg'
import theme from '../../../../themes/theme'
import { getEmailPattern, getPhonePattern } from '../../../../utils'
import MainButton from '../../../../components/Button/mainButton/MainButton'

const Contacts = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const { control, handleSubmit } = useForm()

  const onSubmit = (dataForm) => {
    const body = `Soy ${dataForm.name} mi email es \n${dataForm.email} mi telefono es \n${dataForm.phone} y escribo por \n ${dataForm.message}`

    const mailtoLink = `mailto:${dataForm.email}?subject=${encodeURIComponent(
      'Contacto inceca',
    )}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  return (
    <Container maxWidth='lg' id='contacto'>
      <Box sx={{ py: 8 }}>
        <Grid
          container
          display='flex'
          flexDirection={!matches ? 'row' : 'column'}
          justifyContent='space-between'
        >
          <Grid item xs={12} md={5}>
            <Box>
              <Box mb={4}>
                <Typography fontSize='1.5rem' variant='h3'>
                  Para más información sobre nuestros servicios
                </Typography>
                <Typography>
                  Escríbenos en nuestras lineas principales
                </Typography>
              </Box>
              {/* <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +1 (786) 247-6559
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item alignItems='center' xs={12} md={6}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +1 (954) 850-5512
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Typography>O visitanos en nuestras oficinas</Typography> */}
            </Box>
            {/* <Box mt={3} mb={1}>
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  paddingBottom: '0.5rem',
                }}
                fontSize='1rem'
                variant='h3'
              >
                USA
              </Typography>
              <Typography>
                2745 Ponce de Leon BLVD Coral Gables, FL 33134.
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +1 (786) 247-6559
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box> */}
            <Box mt={3} mb={1}>
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  paddingBottom: '0.5rem',
                }}
                fontSize='1rem'
                variant='h3'
              >
                Venezuela
              </Typography>
              <Typography>
                Avenida Alirio Ugarte Pelayo Edif. Greenmall Complejo Ciudad Comercial Petroriente, Oficina S-34, Piso 1, Maturín, Estado Monagas. Zona Postal 6201.
              </Typography>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +58 (291) 645-2616
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item alignItems='center' xs={12} md={12}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +58 (414) 765-8928
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item alignItems='center' xs={12} md={12}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={emailIcon} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      info@inceca.com.ve
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* <Box mb={6}>
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  paddingBottom: '0.5rem',
                }}
                fontSize='1rem'
                variant='h3'
              >
                España
              </Typography>
              <Typography>
                Calle Italia 3,Bajo B, Torrejon de Ardoz, 28850, Madrid.
              </Typography>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems='center'>
                    <Grid item xs={1}>
                      <img alt='bamba' src={wasop} />
                    </Grid>
                    <Typography marginY='1rem' paddingLeft='1rem'>
                      +34 (691) 068-259
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box> */}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box mb={8}>
              <Grid item width='100%'>
                <Typography fontWeight={700} variant='body1'>
                  Nombre*
                </Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='name'
                  rules={{
                    required: 'El nombre es requerido',
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: errorInput },
                  }) => (
                    <Grid container flexDirection='column' marginTop='.5rem'>
                      <Input
                        error={!!errorInput}
                        height='3rem'
                        id='name-quote'
                        placeholder='Nombre completo'
                        radius='.5rem'
                        onChange={onChange}
                        type='text'
                        value={value}
                        width='100%'
                      />
                      <Typography
                        color='error.main'
                        data-testid='error-message-name'
                        variant='caption'
                      >
                        {errorInput?.message}
                      </Typography>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item mt='1.62rem' width='100%'>
                <Typography fontWeight={700} variant='body1'>
                  Email*
                </Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='email'
                  rules={{
                    required: 'El email es requerido',
                    pattern: getEmailPattern(),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: errorInput },
                  }) => (
                    <Grid container flexDirection='column' marginTop='.5rem'>
                      <Input
                        error={!!errorInput}
                        height='3rem'
                        id='email-quote'
                        placeholder='hola@email.com'
                        radius='.5rem'
                        onChange={onChange}
                        type='text'
                        value={value}
                        width='100%'
                      />
                      <Typography
                        color='error.main'
                        data-testid='error-message-email'
                        variant='caption'
                      >
                        {errorInput?.message}
                      </Typography>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item mt='1.62rem' width='100%'>
                <Typography fontWeight={700} variant='body1'>
                  Teléfono
                </Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='phone'
                  rules={{
                    required: 'El teléfono es requerido',
                    pattern: getPhonePattern(),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: errorInput },
                  }) => (
                    <Grid container flexDirection='column' marginTop='.5rem'>
                      <Input
                        error={!!errorInput}
                        height='3rem'
                        id='company-quote'
                        placeholder='Ingrese número'
                        radius='.5rem'
                        onChange={onChange}
                        type='text'
                        value={value}
                        width='100%'
                      />
                      {errorInput?.message && (
                        <Typography
                          color='error.main'
                          data-testid='error-message-company'
                          variant='caption'
                        >
                          {errorInput?.message}
                        </Typography>
                      )}
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item mt='1.62rem' width='100%'>
                <Typography fontWeight={700} variant='body1'>
                  Mensaje*
                </Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='message'
                  rules={{
                    required: 'El mensaje es requerido',
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error: errorInput },
                  }) => (
                    <Grid container flexDirection='column' marginTop='.5rem'>
                      <Input
                        error={!!errorInput}
                        height='3rem'
                        id='company-quote'
                        placeholder='Escribir un mensaje'
                        radius='.5rem'
                        onChange={onChange}
                        type='text'
                        value={value}
                        width='100%'
                      />
                      <Typography
                        color='error.main'
                        data-testid='error-message-company'
                        variant='caption'
                      >
                        {errorInput?.message}
                      </Typography>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item mt={5}>
                <MainButton onClick={handleSubmit(onSubmit)}>
                  Enviar mensaje
                </MainButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <h2 style={{ textAlign: 'center' }}>No pierdas la oportunidad de ganar para el futuro.</h2>
    </Container>
  )
}

export default Contacts
