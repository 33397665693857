import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'
import {
  Box, Container, Tab, useMediaQuery, Typography,
} from '@mui/material'
import theme from '../../../../themes/theme'
import WellServices from './components/WellServices'
import Construction from './components/Construction'
import ElectronicSystems from './components/ElectronicSystems'

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}

const Services = () => {
  const matches = useMediaQuery('(max-width:600px)')
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container maxWidth='xl' id='servicios'>
      <Box sx={{ px: !matches ? 3 : '' }}>
        <br />
        <br />
        <br />
        <br />
        <Typography variant='h2' fontWeight={500}>
          NUESTROS
        </Typography>
        <Typography variant='h1' color='secondary' fontWeight={800}>
          SERVICIOS
        </Typography>
        <Box
          mt={2}
          sx={{
            height: '2px',
            backgroundColor: theme.palette.secondary.main,
            width: '4.31rem',
          }}
        />
        <Box backgroundColor={theme.palette.background.secondary} sx={{ px: !matches ? 6 : '' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
            aria-label='scrollable auto tabs example'
            sx={{
              mt: 8,
              pt: 3,
              '& .MuiTab-textColorSecondary': {
                color: theme.palette.primary.main,
              },
            }}
            color={theme.palette.primary.main}
            textColor='secondary'
            indicatorColor='secondary'
          >
            <Tab label='Generadores eléctricos' />
            <Tab label='Construcción' />
            <Tab label='Sistemas eléctricos' />
          </Tabs>
          <TabPanel value={value} index={0}>
            <WellServices />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Construction />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ElectronicSystems />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

export default Services
