/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  Box, Container, Typography, useMediaQuery,
} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../../../themes/theme'

function createData(ente, description, alcance) {
  return {
    ente, description, alcance,
  };
}
const rowsAlianza = [
  createData('PETROPIAR, S.A', 'CONTRATO N° 3Z-071-025-A-21-S-0047 / 3Z-071-025-D-21-N-0096. RESTAURACIÓN DEL SISTEMA DE ILUMINACIÓN CON NUEVA TECNOLOGÍA EN ÁREAS OPERACIONALES Y PERIMETRALES DE PETROPIAR, S.A. ', 'Desarrollo de ingeniería para nueva tecnología, mantenimiento mayor, restauración, reparación e instalación del sistema de iluminación y alumbrado de áreas operacionales, viales y perimetrales de PETROPIAR, S.A. con la finalidad de establecer mejoras teconológicas en las condiciones de diseño del sistema de iluminación actual, todo en función de minimizar el consumo enérgetico, proporcionando mayor resistencia y larga durabilidad, debido a una vida útil más prolongada, con el mayor aprovechamiento de la energía posible, y un mínimo de impacto al ambiente por contaminación lumínica.'),
  createData('PETROPIAR, S.A', 'ODS: ACPP2021-006-A2-ODS-001. EJECUCIÓN DE PRUEBAS DIAGNÓSTICO DE LOS CIRCUITOS ELÉCTRICOS ASOCIADOS AL SISTEMA DE ILUMINACIÓN DE LAS ÁREAS PERIMETRALES Y VIALES DEL MEJORADOR, ASÍ COMO EL REEMPLAZO DE LOS REFLECTORES Y LUMINARIAS VIALES POR TECNOLOGÍA LED, DE IGUAL MANERA TODAS LAS ACTIVIDADES CIVILES NECESARIAS PARA LA RESTAURACIÓN DE ESTOS SISTEMAS DEL MEJORADOR PETROPIAR, S.A.', 'Ejecución de Pruebas Diagnóstico de los Circuitos Eléctricos Asociados al Sistema de Iluminación de las Áreas Perimetrales y Viales del Mejorador, así como el Reemplazo de los Reflectores y Luminarias Viales por Tecnología Led, de Igual manera todas las actividades civiles necesarias para la restauración de estos sistemas del Mejorador Petropiar, S.A.'),
  createData('PETROPIAR, S.A', 'ODS: ACPP2021-006-A8-ODS-002. DIAGNÓSTICO Y RESTAURACIÓN DEL SISTEMA DE ILUMINACIÓN DEL ÁREA DE ESTACIONAMIENTO DEL ÁREA DE BOMBEROS DEL MEJORADOR PETROPIAR, S.A. Y EXTENSIÓN DEL CONTROL DE MALEZA ASOCIADO LA OPTIMIZACIÓN DEL SISTEMA ILUMINACIÓN DE LA PERIMETRAL NOR-OESTE DEL MEJORADOR.', 'Diagnóstico y Restauración del Sistema de Iluminación del Área de Estacionamiento del Área de Bomberos del Mejorador Petropiar, S.A. y Extensión del Control de Maleza asociado la Optimización del Sistema Iluminación de la Perimetral Nor-Oeste del Mejorador.'),
  createData('PETROPIAR, S.A', 'ODS: ACPP2021-006-A8-ODS-003. RESTAURACIÓN DEL SISTEMA DE ILUMINACIÓN DE ÁREAS ADMINISTRATIVAS DE PARADA DE PLANTA, LABORATORIO Y SUBESTACIONES ELÉCTRICAS 13 Y 13B, ALMACÉN CENTRAL, EDIFICIO DE CASA DE CAMBIO Y TALLER CENTRAL, AL IGUAL QUE LAS ÁREAS AFECTADAS POR INCENDIO EN LA UNIDAD DE COQUER ESPECÍFICAMENTE EN LAS BOMBAS 12-P-001 A/B/C Y LA TORRE 12-C-001 DEL MEJORADOR PETROPIAR.', 'Restauración del Sistema de Iluminación de Áreas Administrativas de Parada de Planta, Laboratorio y Subestaciones Eléctricas 13 Y 13b, Almacén Central, Edificio de Casa de Cambio y Taller Central, al igual que las Áreas afectadas por Incendio en la Unidad de Coquer específicamente en las Bombas 12-P-001 A/B/C y la Torre 12-C-001 del Mejorador Petropiar.'),
  createData('PETROPIAR, S.A', 'ODS: ACPP2021-006-A8-ODS-004. DIAGNÓSTICO, DESINSTALACIÓN E INSTALACIÓN DEL SISTEMA DE ILUMINACIÓN DE LA UNIDAD DE AZUFRE Y REMANENTES DE LÁMPARAS DE LA UNIDAD DE CRUDO POR EMERGENCIA DE EVENTO EN ÁREA DE TORRE DE VACÍO Y DESALADORES DEL MEJORADOR PETROPIAR, S.A.', 'Diagnóstico, Desinstalación e Instalación del Sistema de Iluminación de la Unidad de Azufre y Remanentes de Lámparas de la Unidad de Crudo por Emergencia de Evento en Área de Torre de Vacío y Desaladores del Mejorador Petropiar, S.A.'),
];
const rowsContrato = [
  createData('PDVSA PETRÓLEO, S.A.', 'CONTRATO N° 4600134811. MANTENIMIENTO EN SUBESTACIONES ELECTRICAS Y LINEAS DE DISTRIBUCIÓN RESIDENCIALES ASOCIADAS A LA SUBESTACIÓN GUARA OESTE DE LA DIVISIÓN AYACUCHO FPO,', 'Colocación de luminarias, poda de árboles en Corredores de líneas de Distribución 138 KV, aplicación de pintura. Rreparación de sistema de iluminaciones, Desmalezamiento, cuadrillas de linieros. Servicio de Camión cesta aislado, servicio de Brazo Hidraúlico. Instalación de luminarias de Alumbrado público, Cuadrilla Técnica de Electricista.'),
];
const rowsAlianza2 = [
  createData('PDVSA PETRÓLEO, S.A.', 'CONTRATO N° 1B-180-004-F-21-N-0138. ALIANZA ESTRATÉGICA PARA LA RECUPERACIÓN DEL SISTEMA ELÉCTRICO DE LA DIRECCIÓN EJECUTIVA DE PRODUCCIÓN ORIENTE. ', 'El servicio consiste en suministro de materiales, equipos, herramientas, mano de obra calificada, personal profesional y equipos necesarios para la ejecución de las actividades para la recuperación del Sistema Eléctrico de la Dirección Ejecutiva  de Producción Oriente'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-001. REEMPLAZO DE AISLADORES 115KV EN LA LÍNEA DE TRANSMISIÓN JUSEPIN PLANTA 9.', 'Ejecución de obras eléctricas, electromecánicas, limpieza y pintura, suministro de materiales varios, así como, las herramientas y equipos necesarios para la ejecución de las actividades de "Reemplazo de aisladores 115KV en la línea de transmisión Jusepin Planta 9", y cualquier otro trabajo que se requiera para alcanzar el objetivo fundamental de este documento.'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-005. RESTITUCIÓN DE LAS CONDICIONES OPERACIONALES DE LA S/E QUIRIQUIRE.', 'Servicio de alquiler de un (01) generador eléctrico de 450 KVA para ser trasladado e instalado por un tiempo determinado en el patio de almacenamiento de la Planta de Petroquiriquire'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-007. MOVILIZACIÓN Y TRASLADO DE EQUIPOS DE POTENCIA PARA LA RECUPERACIÓN DEL SISTEMA ELÉCTRICO DEPO.', 'Suministro de materiales, equipos, herramientas, mano de obra calificada, personal profesional y equipos necesarios para la ejecución de las actividades necesarias para la ejecución de las actividades de Movilización y traslado de Equipos de potencia para la recuperación del sistema eléctrico DEPO, considerando las actividades necesarias para la movilización de equipos estratégicos de SSEE DEPO y servicio y traslado de carga sobredimensionada desde Pariaguan  Edo. Anozoátegui hasta la Subestación Eléctrica Morón Edo. Monagas.'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-024. INSTALACIÓN Y PUESTA EN SERVICIO DEL GENERADOR TAURUS EN PLANTA DE EXTRACCIÓN SANTA BÁRBARA.', 'Suministro de materiales, equipos, herramientas, mano de obra calificada, personal profesional y equipos necesarios para la ejecución de las actividades necesarias para la ejecución de la obra Instación y puesta en servicio del generador taurus en Planta de Extracción Santa Bárbara, considerando las actividades necesarias para las actividades de obras civiles: replanteo y nivelación, excavaciones, acero de refuerzo, encofrado y vaciado de concreto; obras mecánicas: transporte de tuberías y accesorios; fabricación alineación, soldadura y montaje de tuberías, sand blasting y pintura así como obras eléctricas y de instrumentación.'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-004. MANTENIMIENTO DE CORREDORES DE LÍNEAS Y SUBESTACIONES ELÉCTRICAS DE TRANSMISIÓN Y DISTRIBUCIÓN DE LA DEPO.', 'Suministro de mano de obra profesional, técnica y obrera, materiales varios claramente identificados, herramientas y equipos necesarios para la ejecución de actividades de mantenimiento de corredores de líneas de transmisión, así como, la remoción y disposición de desperdicios, corte de maleza de porte alto, medio y bajo, y cualquier otro trabajo que se requiera para alcanzar el objetivo fundamental de este documento.'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-023. RESTITUCIÓN DE LAS CONDICIONES OPERACIONALES DE LA SUBESTACIÓN JUSEPIN II.', 'Suministro de mano de obra profesional, técnica y obrera, materiales varios claramente identificados, herramientas y equipos necesarios para la ejecución de actividades de restitución de las condiciones operacionales de la Subestación Jusepin II, considerando las actividades de acondicionamiento delas casetas de control, caseta de vigilancia, galpon y cerca perimetral. Comprende reconstrucción e impermeabilización de techo, pintura de paredes, mantenimiento de piso y loza, mantenimiento dels sistema de iluminación.'),
  createData('PDVSA PETRÓLEO, S.A.', 'ODS-RSE-2022-025. INSTALACIÓN DEL GENERADOR MÓVIL EN EL CENTRO OPERATIVO FURRIAL.', 'Suministro de materiales, equipos , herramientas, mano de obra calificada, personal profesional y equipos necesarios para la ejecución de las actividades necesarias para la construcción de las facilidades tales como ductos, instalación de componente, instalación de accesorios, mantenimiento de prearranque y puesta en servicio de todos sus sistemas que conforman e integran el grupo electrógeno.'),
  createData('PDVSA PETRÓLEO, S.A.', 'CONTRATO N° DECIJAA-AE-2021-0005. ALIANZA ESTRATÉGICA MANTENIMIENTO Y ADECUACIÓN DE LAS ÁREAS DE SEGURIDAD INTEGRAL Y COMUNES DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA)', 'Suministro y transporte de materiales, supervisión, mano de obra común y especializada, herramientas, consumibles y equipos necesarios para la ejecución del Mantenimiento y Adecuación de las áreas de Seguridad Integral y comunes del Condominio Industrial José Antonio Anzoátegui, con el objetivo de resguardar la integridad física de las instalaciones de PDVSA dentro de la zona de seguridad estratégica del CIJAA. Los trabajos contemplan: mantenimiento, remodelaciones, modificaciones y adecuaciones de las áreas permietrales a la troncal 9.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJJA-AE-2021-0003-ODS-011. MANTENIMIENTO DEL SISTEMA DE ALUMBRADO DE LAS   ÁREAS COMUNES DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA) FASE I.', 'Mantenimiento del Sistema de Alumbrado de las  Áreas Comunes del Complejo Industrial José Antonio Anzoátegui (CIJAA) Fase I.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-2021-0004-ODS-017. SERVICIO DE REPARACIÓN Y MANTENIMIENTO DE EQUIPOS DE COCINA PARA LA PREPARACIÓN DE ALIMENTOS AL PERSONAL OPERACIONAL DE LA DIVISIÓN MEJORAMIENTO.', 'Servicio de Reparación y Mantenimiento de Equipos de Cocina para la Preparación de Alimentos al Personal Operacional de la División Mejoramiento.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-2021-0010-ODS-057. MANTENIMIENTO DEL SISTEMA DE ALUMBRADO DE LAS ÁREAS COMUNES DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA) FASE II.', 'Mantenimiento del Sistema de Alumbrado de las Áreas Comunes del Complejo Industrial José Antonio Anzoátegui (CIJAA) Fase II.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-2021-ODS-0043. DEFORESTACIÓN Y LIMPIEZA EN VIALIDAD PERIMETRAL SUR DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA) TRAMO 3.', 'Las actividades a ejecutar dentro del alcance de EL SERVICIO son las siguientes: Deforestación Mediana. Control químico de maleza mediante la aplicación de herbicida.  Limpieza de vialidad con uso de herramientas menores (pala, cepillo, otros.) y uso de maquinaria.  Limpieza de drenajes con uso de herramientas menores y con uso de maquinaria.  Limpieza de cerca metálica (tipo ciclón y tipo fortaleza).'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-0005-2022-ODS-0070. CONTROL DE MALEZA Y MANTENIMIENTO DE ÁREAS DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI, TRAMO 3.', 'Las actividades a ejecutar dentro del alcance de EL SERVICIO son las siguientes:  Corte manual de maleza con herramientas menores.  Aplicación de herbicida.  Deforestación Mediana.  Mantenimiento de canales de aguas de lluvia.  Limpieza de Cerca Perimetrales.  Recolección y bote de material vegetal.  Barrido de calles, avenidas y estacionamientos. Desraizamiento de árboles y arbustos. '),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-0005-2022-ODS-0112. CONTROL DE MALEZA Y MANTENIMIENTO DE ÁREAS DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI, TRAMO 3 (II-2022).', 'Las actividades a ejecutar dentro del alcance de EL SERVICIO son las siguientes:  Corte manual de maleza con herramientas menores.  Aplicación de herbicida.  Mantenimiento de canales de aguas de lluvia.  Limpieza de Cerca Perimetrales.  Recolección y bote de material vegetal.  Barrido de calles, avenidas y estacionamientos.  Desraizamiento de árboles y arbustos.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-0005-2022-ODS-0006. CONTROL DE MALEZA Y LIMPIEZA DE CANALES EN ÁREAS COMUNES DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA).', 'Las actividades a ejecutar dentro del alcance de EL SERVICIO son las siguientes:  Corte manual de maleza con herramientas menores.  Control químico de maleza mediante la aplicación de herbicida.  Limpieza de drenajes con uso de herramientas menores.'),
  createData('PDVSA PETRÓLEO, S.A.', 'DECIJAA-AE-0027-2022-ODS-0105. MANTENIMIENTO DEL SISTEMA DE ALUMBRADO DE LAS ÁREAS COMUNES DEL COMPLEJO INDUSTRIAL JOSÉ ANTONIO ANZOÁTEGUI (CIJAA) FASE II.', 'Suministro y montaje de luminarias y reflectores tipo LED. Reemplazo de componentes internos y externos en luminarias y reflectores, tales como: ignitores, condensadores, balastos, pantallas de vidrio, fusibles, portafusibles, entre otros.  Acondicionamiento y pruebas de tableros de control de iluminación. Incluye reemplazo de componentes internos y externos: interruptores, fusibles, transformadores de control, contactores, fotoceldas, entre otros.  Montaje y reemplazo de postes hexagonales.  Fabricación y montaje de base de concreto para postes. '),
];

const Obras = () => {
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <Container maxWidth='xl' id='obras'>
      <Box sx={{ px: !matches ? 3 : '' }}>
        <br />
        <br />
        <br />
        <Box mt={3}>
          <Box display='flex' flexWrap='wrap'>
            <Typography
              variant='h2'
              fontWeight={500}
              noWrap
              marginRight='0.75rem'
            >
              EXPERIENCIA EN
            </Typography>
            <Typography variant='h2' color='secondary' fontWeight={800}>
              OBRAS
            </Typography>
          </Box>
          <Box
            mt={2}
            sx={{
              height: '2px',
              backgroundColor: theme.palette.secondary.main,
              width: !matches ? '10.31rem' : '5rem',
            }}
          />
        </Box>
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            style={{ backgroundColor: '#EF9595' }}
          >
            <Typography
              style={{
                fontWeight: 'bold', fontSize: '22px', padding: '50px',
              }}
            >
              ALIANZAS DE SERVICIOS ELÉCTRICOS, LUMINARIAS Y DESMALEZAMIENTO
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ENTE CONTRATANTE</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>DESCRIPCIÓN DE LA ACTIVIDAD EJECUTADA O EN EJECUCIÓN</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ALCANCE DEL SERVICIO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsAlianza.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.ente}
                      </TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='left'>{row.alcance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            style={{ backgroundColor: '#EFD595', borderRadius: '0' }}
          >
            <Typography style={{
              fontWeight: 'bold', fontSize: '22px', padding: '50px',
            }}
            >
              CONTRATOS DE SERVICIOS ELÉCTRICOS, LUMINARIAS Y DESMALEZAMIENTO

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ENTE CONTRATANTE</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>DESCRIPCIÓN DE LA ACTIVIDAD EJECUTADA O EN EJECUCIÓN</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ALCANCE DEL SERVICIO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsContrato.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.ente}
                      </TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='left'>{row.alcance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2a-content'
            id='panel2a-header'
            style={{ backgroundColor: '#EFB495' }}
          >
            <Typography style={{
              fontWeight: 'bold', fontSize: '22px', padding: '50px',
            }}
            >
              ALIANZAS DE SERVICIOS ELÉCTRICOS, LUMINARIAS Y DESMALEZAMIENTO

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ENTE CONTRATANTE</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>DESCRIPCIÓN DE LA ACTIVIDAD EJECUTADA O EN EJECUCIÓN</TableCell>
                    <TableCell align='center' style={{ fontWeight: 'bold' }}>ALCANCE DEL SERVICIO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsAlianza2.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.ente}
                      </TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='left'>{row.alcance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  )
}
export default Obras
