import React from 'react'
import {
  Box, Container, Grid, Typography, useMediaQuery,
} from '@mui/material'
import theme from '../../../../themes/theme'

const OurFocus = () => {
  const matches = useMediaQuery('(max-width:600px)')

  return (
    <Container maxWidth='xl' id='enfoque'>
      <Box sx={{ px: !matches ? 10 : '', pb: 6 }}>
        <Grid container alignItems='center' height={!matches ? '40vh' : 'auto'}>
          <Grid item xs={12} md={6} mt={3}>
            <Typography variant='h2' fontWeight={500}>
              NUESTRO
            </Typography>
            <Typography variant='h1' color='secondary' fontWeight={800}>
              ENFOQUE
            </Typography>
            <Box
              mt={2}
              sx={{
                height: '2px',
                backgroundColor: theme.palette.secondary.main,
                width: '4.31rem',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={2}>
            <Typography
              variant='body1'
              fontSize={!matches ? '1rem' : '0.875rem'}
            >
              Somos una empresa de servicios comprometida con la excelencia
              en cada obra que llevamos a cabo. Contamos con más de 17 años
              de experiencia en el mercado, hemos dejado huella en
              innumerables proyectos que han transformado comunidades.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default OurFocus
